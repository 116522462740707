import { totalValue, mensalValue, acertoMes } from "../utils.js";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { RoadsContract } from "../road/RoadsContract.js";

const ViewContract = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [contract, setContract] = useState({});
  const [roadsContract, setRoadsContract] = useState([]);
  const [sectionsContract, setSectionsContract] = useState([]);
  const [company, setCompany] = useState({});
  const [responsable, setResponsable] = useState({});
  const { id } = useParams();
  const tableHeadColor = {color: '#6080A3'};
  const lisLine = {marginTop: '-1px'};
  const ulmargTop = {marginTop: '-15px'};

  const navigate = useNavigate();

  const getRoadsContract = useCallback(async () => {
    try {
      // Primeira requisição: busca os dados do contrato no primeiro endpoint
      const contractResponse = await axios.get(`${url}/contract/contractRoad/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
  
      if (contractResponse.data) {
        // Se os dados do contrato forem válidos, atualiza o estado
        setContract(contractResponse.data || {});
        setRoadsContract(contractResponse.data?.roads || []);
        setCompany(contractResponse.data?.company || {});
        setSectionsContract(contractResponse.data?.sections || []);
        setResponsable(contractResponse.data?.company?.responsable || {});
        const mappedCompany = {
          name: contractResponse.data.company.name,
          address: contractResponse.data.company.address,
          nif: contractResponse.data.company.nif,
          email: contractResponse.data.company.email === "Empty" ? "-" : contractResponse.data.company.email,
          telephone: contractResponse.data.company.telephone,
          created_at: contractResponse.data.company.created_at,
        };

      } else {
        // Se não houver dados, tenta o segundo endpoint
        const alternativeResponse = await axios.get(`${url}/ecv-integration/contracts/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
  
        if (alternativeResponse.data) {
          // Mapeia os campos do segundo endpoint para o estado esperado
          const mappedContract = {
            id: alternativeResponse.data.contract_id,
            initialDate: alternativeResponse.data.initial_date,
            finalDate: alternativeResponse.data.final_date,
            created_at: alternativeResponse.data.internally_saved_at,
            name: `Contrato Externo ${alternativeResponse.data.external_contract_id}`,
          };
  
          const mappedCompany = {
            name: alternativeResponse.data.company.nome,
            nif: alternativeResponse.data.company.nif,
            email: alternativeResponse.data.company.email === "Empty" ? "-" : alternativeResponse.data.company.email,
            telephone: alternativeResponse.data.company.telephone,
            created_at: alternativeResponse.data.company.created_at,
          };
  
          // Atualiza o estado com os dados mapeados
          setContract(mappedContract);
          setCompany(mappedCompany);
          setResponsable({}); 
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, id]);
  
  
  
  

  useEffect(() => {
    getRoadsContract();
  }, [getRoadsContract]);

  return (
    <div className="container-sm pt-4">
      <p className="small">
        <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
        <Link to='/listContract' className="mutec text-decoration-none ic">Contrato » </Link>
        <span className="ic">Visualizar Contrato</span>
      </p>

      <h5 className="mb-3 floa-start ic" style={{ fontFamily: "Roboto" }}>
        Visualizar Contrato
      </h5>

      <div className="bg-white shadow-lg mb-1 pt-3 pb-1">
        <div className="row mx-auto w-75">
          <div className="col-sm-4">
            <h6 className="ic">Dados do Contrato</h6><hr style={lisLine} />
            <ul className="list-group smollText" style={ulmargTop}>
              <li className="list-group-item border-0">
                <span className="ic">Contrato</span><br />
                <span className="mutec">{contract.name || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Data Inicial</span><br />
                <span className="mutec">
                  {contract.initialDate ? new Date(contract.initialDate).toLocaleDateString() : '-'}
                </span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Data Final</span><br />
                <span className="mutec">
                  {contract.finalDate ? new Date(contract.finalDate).toLocaleDateString() : '-'}
                </span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Proposta Total</span><br />
                <span className="mutec">
                  {contract.hasOwnProperty('name') ? totalValue(roadsContract) + "$00" : '-'}
                </span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Proposta Mensal</span><br />
                <span className="mutec">
                  {contract.hasOwnProperty('name') ? 
                    mensalValue(contract.initialDate, contract.finalDate, roadsContract) + "$00" : '-'}
                </span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Acerto de Ultimo Mês</span><br />
                <span className="mutec">
                  {contract.hasOwnProperty('name') ? 
                    acertoMes(contract.initialDate, contract.finalDate, roadsContract) + "$00" : '-'}
                </span>
              </li>
              <li className="list-group-item border-0 text-left">
                <span className="ic">Data de Criação</span><br />
                <span className="mutec">
                  {contract.created_at ? 
                    new Date(contract.created_at).toLocaleString() : '-'}
                </span>
              </li>
            </ul>
          </div>

          <div className="col-sm-4">
            <h6 className="ic">Dados da Empresa</h6><hr style={lisLine} />
            <ul className="list-group smollText" style={ulmargTop}>
              <li className="list-group-item border-0">
                <span className="ic">Nome</span><br />
                <span className="mutec">{company.name || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Nif</span><br />
                <span className="mutec">{company.nif || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Endereço</span><br />
                <span className="mutec">{company.address || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Email</span><br />
                <span className="mutec">{company.email || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Telefone</span><br />
                <span className="mutec">{company.telephone || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Data Criação</span><br />
                <span className="mutec">{company.created_at || '-'}</span>
              </li>
            </ul>
          </div>

          <div className="col-sm-4">
            <h6 className="ic">Dados do Responsável</h6><hr style={lisLine} />
            <ul className="list-group smollText" style={ulmargTop}>
              <li className="list-group-item border-0">
                <span className="ic">Nome</span><br />
                <span className="mutec">{responsable.name || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Função</span><br />
                <span className="mutec">{responsable.funct || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Email</span><br />
                <span className="mutec">{responsable.email || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Telefone</span><br />
                <span className="mutec">{responsable.telephone || '-'}</span>
              </li>
              <li className="list-group-item border-0">
                <span className="ic">Data Criação</span><br />
                <span className="mutec">{responsable.created_at || '-'}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <RoadsContract
        contractId={id}
        originLink={'view'}
        contract={contract}
        roadsContract={roadsContract}
        sectionsContract={sectionsContract}
      />
    </div>
  );
};

export default ViewContract;
