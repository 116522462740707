import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {
  checkRodSection,
  checkSectionTypeServicesEvaluations,
  dateTimeFormat,
  solveMessage,
} from "../utils";
import { RoadsContract } from "./RoadsContract";
import { toast } from "react-toastify";

const AddRoadContract = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [contract, setContract] = useState([]);
  const [roadsContract, setRoadsContract] = useState([]);
  const [sectionsContract, setSectionsContract] = useState([]);
  const [contractIsClosed, setContractIsClosed] = useState();
  const [contractName, setContractName] = useState("");
  const [initialDate, setInitialDate] = useState();
  const [company_id, setCompany_id] = useState("");
  const [company_name, setCompany_name] = useState('');
  const [company_nif, setCompany_nif] = useState('');
  const [company_email, setCompany_email] = useState('');
  const [company_telephone, setCompany_telephone] = useState('');
  const [company_status, setCompany_status] = useState(true);
  const [company_created_at, setCompany_created_at] = useState('');
  const [company_updated_at, setCompany_updated_at] = useState('');
  const [isExternalContract, setIsExternalContract] = useState('');
  const [finalDate, setFinalDate] = useState();
  const [lote, setLote] = useState("");
  const [gestor, setGestor] = useState("");
  const { contractId } = useParams();
  const [companies, setCompanies] = useState([]);
  const [load, setLoad] = useState(false);

  const [users, setUsers] = useState([]);
  const toastStyle = { fontSize: '13.3px' };
  const getCompanies = useCallback(async () => {
    try {
      // Requisição para `/company`
      const companyResponse = await axios.get(`${url}/company`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });

      // Função para carregar todas as páginas de `/ecv-integration/company`
      const fetchEcvCompanies = async () => {
        let ecvCompanies = [];
        let page = 1;
        let totalPages = 1; // Inicia com 1 para entrar no loop

        while (page <= totalPages) {
          const response = await axios.get(`${url}/ecv-integration/company`, {
            headers: {
              Authorization: "Bearer " + user.access_token,
            },
            params: {
              page, // Define o número da página atual
            },
          });

          const { items, meta } = response.data;

          // Adiciona os itens da página atual à lista
          ecvCompanies = [...ecvCompanies, ...items];

          // Atualiza totalPages a partir dos dados da meta
          totalPages = meta.totalPages;
          page += 1; // Incrementa o número da página
        }

        return ecvCompanies;
      };

      // Carregar todas as empresas da integração
      const ecvIntegrationCompanies = await fetchEcvCompanies();

      // Combinar os dados das duas respostas
      const combinedCompanies = [
        ...companyResponse.data,
        ...ecvIntegrationCompanies,
      ];

      // Definindo os dados combinados no estado
      setCompanies(combinedCompanies);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token]);



  const getContract = useCallback(async () => {
    try {
      // First API call
      const response = await axios.get(`${url}/contract/getOne/${contractId}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      // Set contract data from the first response
      setContractName(response.data.name);
      setContractIsClosed(response.data.isClosed);
      setInitialDate(response.data.initialDate.split('T')[0]);
      setFinalDate(response.data.finalDate.split('T')[0]);
      setLote(response.data.lote);
      setGestor(response.data.gestor);
      setCompany_id(response.data.company.id);
    } catch (error) {
      console.log("First API call failed, trying second API.", error);

      // If the first call fails, attempt the second API call
      try {
        const response = await axios.get(`${url}/ecv-integration/contracts/${contractId}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        // Set contract data from the second response
        setContractName(response.data.name);
        setContractIsClosed(response.data.isClosed);
        setInitialDate(response.data.initial_date.split('T')[0]);
        setFinalDate(response.data.final_date);
        setLote(response.data.lote);
        setGestor(response.data.gestor);
        setCompany_id(response.data.company.id);
        setCompany_id(response.data.company.id);
        setCompany_name(response.data.company.name);
        setCompany_nif(response.data.company.nif);
        setCompany_email(response.data.company.email);
        setCompany_telephone(response.data.company.telephone);
        setCompany_status(response.data.company.status);
        setCompany_created_at(response.data.company.created_at);
        setCompany_updated_at(response.data.company.updated_at);
        setIsExternalContract(response.data.external_contract_id);
      } catch (error) {
        console.log("Second API call also failed.", error);
        // Handle the error or notify the user
        toast.error("Failed to load contract data.", { style: { toastStyle } });
      }
    }
  }, [url, user.access_token, setInitialDate, setFinalDate, setLote, setGestor, setCompany_id]);


  const getUsers = async () => {
    const uri = `${url}/users`;
    console.log(uri);
    const token = user.access_token;
    const headers = { authorization: `Bearer ${token}` };
    const response = await axios.get(uri, { headers });
    const approvedUsers = response.data.filter((u) => u.approved);
    setUsers(response.data);


  };

  const getRoadsContract = useCallback(async () => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${url}/contract/contractRoad/${contractId}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setContract(response.data);
      setRoadsContract(response.data.roads);
      setSectionsContract(response.data.sections);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  }, [
    url,
    user.access_token,
    setRoadsContract,
    setContract,
    setSectionsContract,
  ]);

  useEffect(() => {
    getCompanies();
    getContract();
    getRoadsContract();
    getUsers();
  }, [getCompanies, getRoadsContract, getContract]);

  //sim
  const updateConctract = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      // Primeira tentativa com a API principal
      const response = await axios.put(
        `${url}/contract/${contractId}`,
        {
          initialDate: initialDate,
          finalDate: finalDate,
          company: company_id,
          lote: lote,
          gestor: gestor,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false);
      getContract();
      toast.success("Contrato atualizado com sucesso", { style: toastStyle });
    } catch (error) {
      console.log("Primeira API falhou, tentando segunda API.", error);

      // Segunda tentativa com a API de integração
      try {
        const response = await axios.put(
          `${url}/ecv-integration/contracts/${contractId}`,
          {
            initial_date: initialDate,
            final_date: finalDate,
            status: true, // Definido como true, ajuste conforme necessário
            internally_saved_at: new Date().toISOString(), // Data atual
            updated_at: new Date().toISOString(), // Data atual
            lote: lote, // Lote do parâmetro
            company: {
              id: company_id,
              nome: company_name, // Nome da empresa
              nif: company_nif, // NIF da empresa
              email: company_email, // Email da empresa
              telephone: company_telephone, // Telefone da empresa
              status: true,
              created_at: new Date().toISOString(), // Data de criação
              updated_at: new Date().toISOString(), // Data de atualização
            },
            gestor: gestor
          },
          {
            headers: {
              Authorization: "Bearer " + user.access_token,
            },
          }
        );
        setLoad(false);
        getContract();
        toast.success("Contrato atualizado com sucesso", { style: toastStyle });
      } catch (error) {
        setLoad(false);
        if (error.response && error.response.data && error.response.data.message.includes("lote must be a string")) {
          toast.error("Preencher o compo de  Lote", { style: toastStyle });
        } else {
          toast.error(solveMessage(error), { style: toastStyle });
      }
    }
    }
  };

  //sim
  const openOrCloseContract = async (id, isClose) => {
    if (!contract || roadsContract.length === 0) {
      toast.error("Adicione pelo menos uma Estrada no Contrato", { style: toastStyle });
      return;
    }

    var empyRoadSections = checkRodSection(roadsContract, sectionsContract);
    if (empyRoadSections > 0) {
      toast.warn("Precisas Adicionar Troço nas Estradas");
      return;
    }

    const validated = checkSectionTypeServicesEvaluations(sectionsContract);
    if (validated === false) {
      toast.error("Precisas Adicionar Ponderação para todo tipo de serviço", { style: toastStyle });
      return;
    }

    try {
      const res = await axios.put(
        `${url}/contract/openClose/${id}`,
        { isClosed: isClose === false ? true : false },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      getRoadsContract();
      getContract();
    } catch (error) {
      console.log(error);
    }
  };

  const reloadRoadsContract = async () => {
    await getRoadsContract();
  };

  return (
    <div className="container-sm pt-4">
      <div className="mx-auto">
        <p className="small">
          <Link to="/" className="mutec text-decoration-none ic">
            Home »{" "}
          </Link>
          <Link to="/listContract" className="mutec text-decoration-none ic">
            Contrato »
          </Link>
          <span className="ic">Editar Contrato</span>
        </p>

        <div className="clearfix">
          <button
            type="button"
            onClick={() => openOrCloseContract(contract.id, contract.isClosed)}
            className={"btn btn-sm float-end px-2 sidItemL2 text-white mb-4"}
          >
            {contractIsClosed === false ? "Iniciar Contato" : "Parar Contrato"}
            <i
              className={
                contractIsClosed === true
                  ? "fas fa-play-circle ps-2"
                  : "fas fa-stop-circle ps-2"
              }
            ></i>
          </button>

          <h5 className="ic" style={{ fontFamily: "Roboto" }}>
            Editar Contrato
          </h5>
        </div>
        <div className="bg-white shadow-lg mb-1">
          <form
            className="mx-auto w-75 smollText"
            onSubmit={updateConctract}
            style={{
              backgroundColor: "#FFFFFF",
              paddingBottom: "35px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <div className="row g-3">
              <div className="clearfix">
                <h6 className="float-start me-2">Contrato:</h6>
                <span className="smollText mutec">{contractName}</span>
              </div>
              <div className="col-md-3">
                <label for="company_id" className="form-label labeFormMargin">
                  Empresa
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <select
                  id="company_id"
                  className="form-select form-select-sm"
                  value={company_id}
                  onChange={(e) => setCompany_id(e.target.value)}
                  disabled
                >
                  <option value=""></option>
                  {companies.map((company) => (
                    <option value={company.id}>{company.name || company.nome}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="lote" className="form-label labeFormMargin">
                  Lote
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  className="form-control form-control-sm"
                  id="lote"
                  type="text"
                  placeholder=""
                  value={lote}
                  onChange={(e) => setLote(e.target.value)}
                />
              </div>

              <div className="col-md-3">
                <label
                  for="novoinitialDate"
                  className="form-label labeFormMargin"
                >
                  Data Inicial
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  name="date"
                  type="date"
                  className="form-control form-control-sm"
                  id="novoinitialDate"
                  placeholder=""
                  value={dateTimeFormat(initialDate)}
                  onChange={(e) => setInitialDate(e.target.value)}
                  disabled
                />
              </div>

              <div className="col-md-3">
                <label for="finalDate" className="form-label labeFormMargin">
                  Data Final
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>
                <input
                  name="date"
                  type="date"
                  className="form-control form-control-sm"
                  id="finalDate"
                  placeholder=""
                  value={dateTimeFormat(finalDate)}
                  onChange={(e) => setFinalDate(e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="row g-3">
              <div className="col-md-6">
                <label htmlFor="gestor" className="form-label labeFormMargin">
                  Gestor
                  <span className="text-danger" style={{ fontSize: "22px" }}>
                    *
                  </span>
                </label>

                <select
                  id="gestor"
                  className="form-select form-select-sm"
                  value={gestor}
                  onChange={(e) => setGestor(e.target.value)}
                >
                  <option value=""></option>
                  {users &&
                    users.map((user) => (
                      <option value={user.id} key={user.id}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6"></div>
            </div>

            <button
              type="submit"
              className="btn btn-sm text-blue mt-2 borderbp ic2"
              disabled={contract.isClosed === true ? true : false}
              style={{ width: "24.2%" }}
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm ic"></span>
              ) : (
                "Editar"
              )}
            </button>
          </form>
        </div>

        <RoadsContract
          contractId={contractId}
          originLink={"list"}
          contract={contract}
          roadsContract={roadsContract}
          sectionsContract={sectionsContract}
          reloadRoadsContract={reloadRoadsContract}
          isExternalContract={isExternalContract}
        ></RoadsContract>
      </div>
    </div>
  );
};

export default AddRoadContract;
