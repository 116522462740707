import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";

const ListCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const [totalPages, setTotalPages] = useState(0); // Estado para o total de páginas
  const { user } = useAuth();

  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "nif", value: "NIF" },
    { key: "address", value: "Endereço" }, 
    { key: "email", value: "Email" },
    { key: "telephone", value: "Telefone" },
    { key: "responsable", value: "Responsável" }, 
  ];

  // Função para buscar os dados da API antiga
  const getOldApiCompanies = useCallback(async () => {
    const response = await axios.get(`${url}/company`, {
      headers: {
        Authorization: "Bearer " + user.access_token,
      },
    });

    const data = Array.isArray(response.data) ? response.data : [response.data];

    return data.map((v) => ({
      id: v.id,
      name: v.name,
      nif: v.nif,
      address: v.address,
      email: v.email,
      telephone: v.telephone,
      responsable: v.responsable ? v.responsable.name : "N/A",
      created_at: v.created_at,
      source: "old", 
    }));
  }, [url, user.access_token]);

  // Função para buscar os dados da nova API com paginação
  const getNewApiCompanies = useCallback(async () => {
    let allCompanies = [];
    let currentPage = 1;
    let totalPages = 1;

    do {
      const response = await axios.get(`${url}/ecv-integration/company`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
        params: {
          page: currentPage, // Adiciona o parâmetro de página na requisição
        },
      });

      const { items, meta } = response.data; // Acessa os "items" e "meta" da nova API
      totalPages = meta.totalPages; // Obtem o total de páginas

      const formattedItems = items.map((v) => ({
        id: v.id,
        name: v.nome,
        nif: v.nif,
        address: " - ",  // Nova API não tem 'address', usamos valor padrão
        email: v.email === "Empty" ? " - " : v.email,
        telephone: v.telephone,
        responsable: " - ",  // Nova API não tem 'responsable', usamos valor padrão
        created_at: v.created_at,
        source: "new",
      }));

      allCompanies = [...allCompanies, ...formattedItems]; // Adiciona os novos dados
      currentPage++; // Incrementa a página
    } while (currentPage <= totalPages);

    return allCompanies; // Retorna todas as empresas
  }, [url, user.access_token]);

  // Função para remover duplicatas com base no campo 'id'
  const mergeCompanies = (oldCompanies, newCompanies) => {
    const merged = [...oldCompanies];
    
    newCompanies.forEach((newCompany) => {
      const exists = merged.find((company) => company.id === newCompany.id);
      if (!exists) {
        merged.push(newCompany);
      }
    });

    return merged;
  };

  // Combinar os dados das duas APIs sem duplicatas
  const getCompanies = useCallback(async () => {
    try {
        const [oldApiCompanies, newApiCompanies] = await Promise.all([
            getOldApiCompanies(),
            getNewApiCompanies(),
        ]);
        
        const combinedData = mergeCompanies(oldApiCompanies, newApiCompanies);

        // Ordenar as empresas por data de criação (created_at)
        combinedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setCompanies(combinedData);
        setFiltered(combinedData);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
}, [getOldApiCompanies, getNewApiCompanies]);


  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = companies.filter(
      (company) =>
        company.name.match(pattern) ||
        company.address.match(pattern) ||
        company.email.match(pattern) ||
        company.responsable.match(pattern)
    );
    setFiltered(results);
  };

  const deleteCompany = async (id) => {
    try {
      await axios.delete(`${url}/company/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Empresa"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listCompany#"
      newLink="/addCompany"
      viewBaseLink="/viewCompany"
      editBaseLink="/editCompany"
      deleteItem={deleteCompany}
      loading={loading}
    />
  );
};

export default ListCompany;
