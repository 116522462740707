import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import {toast } from 'react-toastify';
import axios from "axios";
import { solveMessage } from "../utils";

const EditCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [responsable_id, setResponsable_id] = useState("");
  const [responsables, setResponsable] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nif, setNif] = useState("");
  const [load, setLoad] = useState(false);

  const [namer, setNamer] = useState("");
  const [telephoner, setTelephoner] = useState("");
  const [resicende, setResicende] = useState("");
  const [funct, setFunct] = useState("");
  const [emailr, setEmailr] = useState("");
  
  
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const getCompanyById = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/company/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      
      // Assuming the first API returns the expected structure
      setName(response.data[0].name);
      setNif(response.data[0].nif);
      setAddress(response.data[0].address);
      setEmail(response.data[0].email);
      setTelephone(response.data[0].telephone);
      setResponsable_id(response.data[0].responsable.id);
      
      setNamer(response.data[0].responsable.name);
      setTelephoner(response.data[0].responsable.telephone);
      setResicende(response.data[0].responsable.resicende);
      setFunct(response.data[0].responsable.funct);
      setEmailr(response.data[0].responsable.email);
      
    } catch (error) {
      console.log("First API call failed:", error);
      // If the first API fails, try the second one
      try {
        const response = await axios.get(`${url}/ecv-integration/company/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        // Assuming the second API returns a different structure
        setName(response.data.nome);
        setNif(response.data.nif);
        setAddress(""); // You might want to set a default or handle it differently
        setEmail(response.data.email);
        setTelephone(response.data.telephone);
        setResponsable_id(""); // Adjust accordingly if there's a responsible field in this API
  
        // If there's no responsible data in the second API, handle it accordingly
        setNamer(""); 
        setTelephoner(""); 
        setResicende(""); 
        setFunct(""); 
        setEmailr("");
        
      } catch (error) {
        console.log("Second API call also failed:", error);
        toast.error("Failed to load company data.");
      }
    }
  },  [
    url,
    id,
    user.access_token,
    setName,
    setNif,
    setAddress,
    setEmail,
    setTelephone,
    setResponsable_id,
  ]);
  


  useEffect(() => {
    getCompanyById();
  }, [getCompanyById]);

  const updateCompany = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
        const response = await axios.put(
            `${url}/company/${id}`,
            {
                // Payload for the first API
                name: name,
                nif: nif,
                address: address,
                email: email,
                telephone: telephone,
                responsable: responsable_id,
                // Responsable
                namer: namer,
                funct: funct,
                emailr: emailr,
                telephoner: telephoner,
                resicende: resicende,
            },
            {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                },
            }
        );

        // Handle successful response
        setLoad(false);
        toast.success("Atualizado", { style: { fontSize: '13.3px' } });
        navigate("/listCompany");
    } catch (error) {
        // If the first API fails, attempt to use the second one
        try {
            const response = await axios.put(
                `${url}/ecv-integration/company/${id}`,
                {
                    // Payload for the second API
                    nome: name, // Assuming 'name' corresponds to 'nome' in the second API
                    nif: nif,
                    email: email,
                    telephone: telephone,
                    status: true, // Assuming default status
                    created_at: new Date().toISOString(), // Set created_at to current date
                    updated_at: new Date().toISOString()  // Set updated_at to current date
                },
                {
                    headers: {
                        Authorization: "Bearer " + user.access_token,
                    },
                }
            );

            // Handle successful response for the second API
            setLoad(false);
            toast.success("Atualizado", { style: { fontSize: '13.3px' } });
            navigate("/listCompany");
        } catch (secondError) {
            setLoad(false);
            toast.error(solveMessage(secondError), { style: { fontSize: '13.3px' } });
        }
    }
};


  return (
    <div className="container-sm pt-4 text-black">
      <div className="mx-auto w-75"> 
          <p className="small" style={{marginLeft: '105px'}}>
            <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
            <Link to='/listCompany' className="mutec text-decoration-none ic">Empresa  »  </Link>
            <span className="ic">Editar Empresa</span>
          </p> 

          <h5 className=" mb-3 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
            Editar Empresa
          </h5>
      
        <form className="p-3 mx-auto w-75 shadow-lg bg-white smollText" onSubmit={updateCompany}>
          <p className="mediunText" style={{color:"#122C48"}}>Empresa:</p><hr className="ic2"></hr>
          <div className="row g-3">
            <div className="col-md">
              <label for="name" className="form-label labeFormMargin">
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="nif" className="form-label labeFormMargin">
                Nif
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="nif"
                type="text"
                placeholder=""
                value={nif}
                onChange={(e) => setNif(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="address" className="form-label labeFormMargin">
                Endereço
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="address"
                type="text"
                placeholder=""
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="email" className="form-label labeFormMargin">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="text"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="telephone" className="form-label labeFormMargin">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="Telefone"
                type="text"
                placeholder=""
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
              />
            </div>
            <div className="col-md">
            </div>
          </div>

          <p className="mt-4 mediunText" style={{color:"#122C48"}}>Responsavel:</p><hr className="ic2"></hr>

          <div className="row g-3">
            <div className="col-md">
              <label for="name" className="form-label labeFormMargin">
                Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="name"
                type="text"
                placeholder=""
                value={namer}
                onChange={(e) => setNamer(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="funct" className="form-label labeFormMargin">
                Função
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="funct"
                type="text"
                placeholder=""
                value={funct}
                onChange={(e) => setFunct(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="email" className="form-label labeFormMargin">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="text"
                placeholder=""
                value={emailr}
                onChange={(e) => setEmailr(e.target.value)}
              />
            </div>
            <div className="col-md">
              <label for="telephone" className="form-label labeFormMargin">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="telephone"
                type="text"
                placeholder=""
                value={telephoner}
                onChange={(e) => setTelephoner(e.target.value)}
              />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-md">
              <label for="resicende" className="form-label labeFormMargin">
                Residência
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="resicende"
                type="text"
                placeholder=""
                value={resicende}
                onChange={(e) => setResicende(e.target.value)}
              />
            </div>
            <div className="col-md"></div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
            <Link
              to="/listCompany"
              type="button"
              className="btn btn-sm px-3 float-end me-2 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCompany;
