import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";
import { formatDate, formatDateTime } from "../utils";

const ListContrct = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [contracts, setContracts] = useState([]);
  const { user } = useAuth();

  const columnTitles = [
    { key: "created_at", value: "Data Criação" },
    { key: "initialDate", value: "Data Inicial" },
    { key: "finalDate", value: "Data Final" },
    { key: "name", value: "Contrato" },
    { key: "company", value: "Empresa" },
    { key: "contratType", value: "Fonte/Utilizador" },
  ];
  const getContracts = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/contract/all`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
  
      // Mapeamento dos contratos da primeira API
      const data = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
        initialDate: formatDate(v.initialDate),
        finalDate: formatDate(v.finalDate),
        company: v.company.name,
        contratType: "Interno",
        name: v.name,
      }));
  
      // Fetch all pages from the second API
      const ecvContracts = await fetchAllEcvContracts(url, user.access_token);
      
      // Combinando os dados
      let finalData = data.concat(ecvContracts);
  
      // Ordenar os contratos por data de criação (mais recentes primeiro)
      finalData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
      setContracts(finalData);
      setFiltered(finalData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);
  

  // Function to fetch all pages from the ECV integration API
  const fetchAllEcvContracts = async (url, token) => {
    let allContracts = [];
    let currentPage = 1;
    let totalPages = 1;

    do {
      const response = await axios.get(`${url}/ecv-integration/contracts`, {
        headers: {
          Authorization: "Bearer " + token,
        },
        params: { page: currentPage }, // Add pagination parameter
      });

      // Mapeamento dos contratos da segunda API
      const dataFromEcv = response.data.items.map((v) => ({
        id: v.contract_id,
        created_at: formatDateTime(v.internally_saved_at),
        initialDate: formatDate(v.initial_date),
        finalDate: formatDate(v.final_date),
        company: " - ",
        contratType: "Externo",
        name: `Contrato ${v.contract_id}`,
        
      }));

      allContracts = allContracts.concat(dataFromEcv);
      totalPages = response.data.meta.totalPages; // Get the total number of pages
      currentPage++;
    } while (currentPage <= totalPages);

    return allContracts;
  };

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const deleteContract = async (id) => {
    try {
      await axios.delete(`${url}/contract/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getContracts();
    } catch (error) {
      console.log(error);
    }
  };

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = contracts.filter(
      (contract) =>
        contract.name.match(pattern) || contract.company.match(pattern)
    );
    setFiltered(results);
  };

  return (
    <List
      title="Contrato"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listContract#"
      newLink="/addContract"
      viewBaseLink="/viewContract"
      editBaseLink="/addRoadContract"
      deleteItem={deleteContract}
      loading={loading}
    />
  );
};

export default ListContrct;
